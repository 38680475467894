import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [newOrderDropdownVisible, setNewOrderDropdownVisible] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleDropdown1MouseEnter = () => {
    setShowDropdown1(true);
  };

  const handleDropdown1MouseLeave = () => {
    setShowDropdown1(false);
  };

  const handleDropdown2MouseEnter = () => {
    setShowDropdown2(true);
  };

  const handleDropdown2MouseLeave = () => {
    setShowDropdown2(false);
  };

  const openNewOrderDropdown = () => {
    setNewOrderDropdownVisible(true);
  };

  const closeNewOrderDropdown = () => {
    setNewOrderDropdownVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !event.target.classList.contains('dropdown') &&
        !event.target.classList.contains('specific-dropdown')
      ) {
        setShowMenu(false);
        setShowDropdown1(false);
        setShowDropdown2(false);
        setNewOrderDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showMenu, showDropdown1, showDropdown2, newOrderDropdownVisible]);

  return (
    <nav ref={menuRef}>
      {/* Menu toggle */}
      <div className="menu-toggle" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      <ul className={`nav-links ${showMenu ? 'show' : ''}`}>
        <li>
          <Link to="/" onClick={() => setShowMenu(false)}>
            Home
          </Link>
        </li>

        {/* Dropdown 1 */}
        <li
          className="dropdown"
          onMouseEnter={handleDropdown1MouseEnter}
          onMouseLeave={handleDropdown1MouseLeave}
        >
          <div className='enquire_now'>
           Enquire Now  <span style={{ fontSize: '12px'}}>&#9660;</span>
          </div>
          <div className={`dropdown-content ${showDropdown1 ? 'show' : ''}`}>
            <Link to="/newsite" onClick={() => setShowMenu(false)}>
              New Site
            </Link>
            <Link to="/rates" onClick={() => setShowMenu(false)}>
              Existing Site
            </Link>
            <Link to="/stock" onClick={() => setShowMenu(false)}>
              Stock
            </Link>
          </div>
        </li>

        {/* Dropdown 2 */}
        <li
          className="dropdown"
          onMouseEnter={handleDropdown2MouseEnter}
          onMouseLeave={handleDropdown2MouseLeave}
        >
          <div className='my-order'>
            My Orders <span style={{ fontSize: '12px'}}>&#9660;</span>
          </div>
          <div className={`dropdown-content ${showDropdown2 ? 'show' : ''}`}>
            <div
              className="dropdown"
              onMouseEnter={openNewOrderDropdown}
              onMouseLeave={closeNewOrderDropdown}
            >
              <div>
                New Order <span style={{ fontSize: '14px' }}>&#9658;</span>
              </div>
              <div className={`dropdown-content ${newOrderDropdownVisible ? 'show' : ''} specific-dropdown`}>
                <Link to="/cement-order" onClick={() => setShowMenu(false)}>
                  Cement
                </Link>
                <Link to="/steel-order" onClick={() => setShowMenu(false)}>
                  Steel
                </Link>
                <Link to="/machinery" onClick={() => setShowMenu(false)}>
                  Machinery
                </Link>
              </div>
            </div>
            <Link to="/status" onClick={() => setShowMenu(false)}>
              Order Status
            </Link>
          </div>
        </li>

        <li>
          <Link to="/finance" onClick={() => setShowMenu(false)}>
            Finance
          </Link>
        </li>
        <li>
          <Link to="/careers" onClick={() => setShowMenu(false)}>
            Careers
          </Link>
        </li>

        <li>
          <Link to="/vendor" onClick={() => setShowMenu(false)}>
            Vendor
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
