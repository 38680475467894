import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase';
import './Order.css';

const OrderStatus = () => {
  const [plusViewData, setPlusViewData] = useState({});
  const [dispatchData, setDispatchData] = useState({});
  const [pendingData, setPendingData] = useState({});
  const [balanceData, setBalanceData] = useState({});
  const [sbalanceData, setsBalanceData] = useState({});
  const [abalanceData, setaBalanceData] = useState({});
  const [jkbalanceData, setJkBalanceData] = useState({});
  const [accbalanceData, setAccBalanceData] = useState({});
  const [dispatchmore, setdispatchmore] = useState({});
  const [pendingmore, setpendingmore] = useState({});
  const [showMoreDispatch, setShowMoreDispatch] = useState(false);
  const [showMorePending, setShowMorePending] = useState(false);
   const [latestOrder, setLatestOrder] = useState(null);
  

  // const handleCircleClick = (circleId) => {
  //   setInfoData((prevData) => ({
  //     ...prevData,
  //     [circleId]: {
  //       ...prevData[circleId],
  //       isVisible: !prevData[circleId].isVisible,
  //     },
  //   }));
  //   setShowAlert(true);
  // };

  // const handleAlert = (circleId) => {
  //   if (infoData[circleId].isVisible && showAlert) {
  //     alert(` ${circleId}: ${infoData[circleId].content}`);
  //     setShowAlert(false);
  //   }
  // };

  const toggleInfoDispatch = () => {
    setShowMoreDispatch(!showMoreDispatch);
  };

  const toggleInfoPending = () => {
    setShowMorePending(!showMorePending);
  };

  const user = firebase.auth().currentUser;

  useEffect(() => {
    if (user) {
      const plusViewRef = firebase.database().ref('Plus View').child(user.uid);
      plusViewRef.on('value', (snapshot) => {
        const data = snapshot.val() || {}; // Handle null or undefined
        setPlusViewData(data);
  
        const ordersArray = Object.values(data);
        const sortedOrders = ordersArray.sort((a, b) => a.timestamp - b.timestamp); // Sort in ascending order
        const latestOrder = sortedOrders[sortedOrders.length - 1]; // Get the last item which is the latest order
        setLatestOrder(latestOrder);
  
      // Update the "pending_more" node with the latest order
if (latestOrder) {
  const pendingMoreRef = firebase.database().ref('profile/' + user.uid + '/pending_more');
  pendingMoreRef.set(latestOrder); // Set the latest order directly without a label
}
      });

      const dispatchRef = firebase.database().ref('profile/' + user.uid + '/dispatch');
      dispatchRef.on('value', (dispatchSnapshot) => {
        const dispatchData = dispatchSnapshot.val() || {}; // Handle null or undefined
        setDispatchData(dispatchData);
      });

      const dispatchmoreRef = firebase.database().ref('profile/' + user.uid + '/dispatch_more');
      dispatchmoreRef.on('value', (dispatchSnapshot) => {
        const dispatchmore = dispatchSnapshot.val() || {}; // Handle null or undefined
        setdispatchmore(dispatchmore);
      });

      const pendingRef = firebase.database().ref('profile/' + user.uid + '/pending');
      pendingRef.on('value', (pendingSnapshot) => {
        const pendingData = pendingSnapshot.val() || {}; // Handle null or undefined
        setPendingData(pendingData);
      });

      const pendingmoreRef = firebase.database().ref('profile/' + user.uid + '/pending_more');
      pendingmoreRef.on('value', (pendingSnapshot) => {
        const pendingmore = pendingSnapshot.val() || {}; // Handle null or undefined
        setpendingmore(pendingmore);
      });

        // Fetch data from the "balance" node
        const balanceRef = firebase.database().ref('profile/' + user.uid + '/ult_amt');
        balanceRef.on('value', (balanceSnapshot) => {
          const balanceData = balanceSnapshot.val() || {}; // Handle null or undefined
          setBalanceData(balanceData);
        });
        const sbalanceRef = firebase.database().ref('profile/' + user.uid + '/shr_amt');
        sbalanceRef.on('value', (balanceSnapshot) => {
          const sbalanceData = balanceSnapshot.val() || {}; // Handle null or undefined
          setsBalanceData(sbalanceData);
        });
        const abalanceRef = firebase.database().ref('profile/' + user.uid + '/jsw_amt');
        abalanceRef.on('value', (balanceSnapshot) => {
          const abalanceData = balanceSnapshot.val() || {}; // Handle null or undefined
          setaBalanceData(abalanceData);
        });
//         const jkbalanceRef = firebase.database().ref('profile/' + user.uid + '/jksuper_amt');

// // Assume you have an input field with an onChange handler
//        const handleInputChange = (event) => {
//       const inputValue = event.target.value;
//       jkbalanceRef.set(inputValue)
//       .then(() => {
//       console.log('jksuper_amt set successfully.');
//       })
//       .catch((error) => {
//       console.error('Error setting jksuper_amt:', error);
//       });
//     };
//       // Listen for changes to the jksuper_amt field
//        jkbalanceRef.on('value', (balanceSnapshot) => {
//       const jkbalanceData = balanceSnapshot.val() || {}; // Handle null or undefined
//        setJkBalanceData(jkbalanceData);
//          });
        
        const accbalanceRef = firebase.database().ref('profile/' + user.uid + '/acc_amt');
        accbalanceRef.on('value', (balanceSnapshot) => {
          const accbalanceData = balanceSnapshot.val() || {}; // Handle null or undefined
          setAccBalanceData(accbalanceData);
        });

        return () => {
          // Clean up all Firebase listeners to avoid memory leaks
          plusViewRef.off();
          dispatchRef.off();
          dispatchmoreRef.off();
          pendingRef.off();
          pendingmoreRef.off();
          balanceRef.off();
          sbalanceRef.off();
          abalanceRef.off();
          // jkbalanceRef.off();
          accbalanceRef.off();
        };
      }
    }, [user]);

    const handleBalanceInfo = ()=>{
      alert("Balance Info : In this field, your current balance will be shown after dispatch.")
    }
    const  handledispatchInfo = ()=>{
      alert("Dispatch Info :In the dispatch field, users can find updates on their shipment, including tracking numbers and shipping dates.")
    }
    const handlePendingInfo = ()=>{
      alert("Pending Info : After confirming your total payment, your pending amount will be automatically displayed.")
    }

  return (
    <div className="orderstatus-page">
      <h1 className="page-heading">Order details</h1>
      <div className="order-containers">
        <div className="right-card">
        <div className="order-container balance">
  <div>
      
<div className='balance-data' >Your Balance <img src={`${process.env.PUBLIC_URL}/Images/info-icon.png`} onClick={handleBalanceInfo} className='info-img'  /></div>
 

  {(balanceData && (balanceData > 0 || sbalanceData > 0 || abalanceData > 0 || jkbalanceData > 0 || accbalanceData > 0)) ? (
    <div className="balance-card">
      {balanceData > 0 && (
        <p>
          <strong>Ultratech Amount (₹):</strong>{" "}
          <span style={{ color: "blue", fontWeight: "bold" }}>
            {balanceData}
          </span>
        </p>
      )}
      {sbalanceData > 0 && (
        <p>
          <strong>Shree Amount (₹):</strong>{" "}
          <span style={{ color: "blue", fontWeight: "bold" }}>
            {sbalanceData}
          </span>
        </p>
      )}
      {abalanceData > 0 && (
        <p>
          <strong>JSW Amount (₹):</strong>{" "}
          <span style={{ color: "blue", fontWeight: "bold" }}>
            {abalanceData}
          </span>
        </p>
      )}
      {/* {jkbalanceData > 0 && (
        <p>
          <strong>JK Super Amount (₹):</strong>{" "}
          <span style={{ color: "blue", fontWeight: "bold" }}>
            {jkbalanceData}
          </span>
        </p>
      )} */}
      {accbalanceData > 0 && (
        <p>
          <strong>Acc Amount (₹):</strong>{" "}
          <span style={{ color: "blue", fontWeight: "bold" }}>
            {accbalanceData}
          </span>
        </p>
      )}
    </div>
  ) : (
    <p>No data available</p>
  )}
</div>
          <div className="order-container dispatch">
      
        <div className='balance-data' >Dispatched Order <img src={`${process.env.PUBLIC_URL}/Images/info-icon.png`} onClick={handledispatchInfo} className='info-img'/></div>
      
      {dispatchData ? (
        <div className="dispatch-card">
          <p>
            <strong>Dispatch(Bags):</strong>{" "}
            <span style={{ color: "blue", fontWeight: "bold" }}>
              {JSON.stringify(dispatchData)}
            </span>
          </p>
          {showMoreDispatch && (
            <p>
              <strong>More details:</strong>{" "}
              <span style={{ color: "blue", fontWeight: "bold" }}>
                {JSON.stringify(dispatchmore)}
              </span>
            </p>
          )}
    <button onClick={toggleInfoDispatch}> {showMoreDispatch ? 'Hide Details' : 'Show More'}</button>
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
          <div className="order-container pending">
            
         <div className='balance-data' >Pending Order 
         <img src={`${process.env.PUBLIC_URL}/Images/info-icon.png`} onClick={handlePendingInfo} className='info-img'/>
          </div>
     
    </div>

            {pendingData ? (
              <div className="pending-card">
                <p>
                  <strong>Pending(Bags):</strong>{" "}
                  <span style={{ color: "blue", fontWeight: "bold" }}>
                    {JSON.stringify(pendingData)}
                  </span>
                </p>
                {showMorePending && (
            <p>
              <strong>More details:</strong>{" "}
              <span style={{ color: "blue", fontWeight: "bold" }}>
                {JSON.stringify(pendingmore)}
              </span>
            </p>
          )}
         <button onClick={toggleInfoPending}>{showMorePending ? 'Hide Details' : 'Show More'}</button>
        </div>
      ) : (
        <p>No data available</p>
      )}
          </div>
        </div>

        <div className="order-container  left-card">
          <h2>Your Cement Orders</h2>
          <div className="order-scroll">
            {Object.keys(plusViewData).length > 0 ? (
              Object.keys(plusViewData)
                .reverse()
                .map((orderId) => (
                  <div key={orderId} className="order-item">
                    <div className="order-details">
                      <ul>
                        {Object.entries(plusViewData[orderId]).map(([name, data]) => (
                          <li key={name}>
                            <strong>{name}:</strong> {data}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))
            ) : (
              <p>No order made yet!</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatus;