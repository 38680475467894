import React,{useState} from 'react';
import './Contactus.css';

function ContactUs() {
  const [apiKey,] = useState('');
  const [campaignName,] = useState('Altrange');
  const [destination,] = useState('+919592080108');
  const [userName,] = useState('');
  const [source,] = useState('');
  const [mediaUrl,] = useState('');
  const [mediaFilename,] = useState('');
  const [templateParams,] = useState([]);
  const [tags, ] = useState([]);
  const [response, setResponse] = useState({});

   const handleWhatsAppClick = async () => {
    try {
      const whatsappApiUrl = process.env.REACT_APP_WHATSAPP_API_URL;
    const data = {
      apiKey: process.env.REACT_APP_WHATSAPP_API_KEY,
      campaignName: 'Altrange',
      destination: '+919592080108',
        userName,
        source,
        media: {
          url: mediaUrl,
          filename: mediaFilename,
        },
        templateParams,
        tags,
      };
  
      const response = await fetch(whatsappApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json();
      setResponse(responseData);
  
      // Open the WhatsApp chat directly
      const whatsappUrl = `https://wa.me/${destination}`;
      window.open(whatsappUrl, '_blank');
    } catch (error) {
      console.error('Error sending WhatsApp message:', error);
    }
  };
  
  return (
    <div className="contact-bg">
    <div className="contact-container">
      <div className="company-address">
        <h1 style={{fontWeight:'bold'}}>Get in Touch with Us</h1>
        <p>
        We'd Love to Hear from You! Whether you have questions,<br/> need assistance, or just want to connect, our team is here for you.
        </p>
      </div>

      <div className="contact">
        <h2>Contact Us</h2>
        <p>
          Call us :{' '}
          <a href="tel:0172-5350108">0172-5350108</a>,{' '}
          <a href="tel:98769-80108">98769-80108</a>
        </p>
        <p>
          Mail us :{' '}
          <a href="mailto:support@altrange.com">support@altrange.com</a>
        </p>
      </div>
      <div className='btnFlex'>
      <section id="click-to-call">
        
        <a href="tel:0172-5350108" className="call-now-button">
          Call Now
        </a>
      </section>

      <section className='enq-whatsapp'>
        <div className='div-whatsp' onClick={handleWhatsAppClick}><span>Whatsapp</span> <img src={process.env.PUBLIC_URL + "/Images/whatsapp.png"} /></div>
      </section>
      </div>
    </div>
    </div>
  );
}

export default ContactUs;
